import { environment as env } from 'src/environments/environment';
import { ApplicationRoutes } from './app-route.config';

export const RoleBasedConfig = {
  company: {
    profileType: 'company',
    apiOfprofileImage: {
      get: `${env.hosts.companyApiHost}/api/companyimages/getprofileimage`,
      post: `${env.hosts.companyApiHost}/api/companyimages/uploadprofilepicture`,
    },
    urlOfProfile: ApplicationRoutes.controlpanel.profile,
    urlOfDashboard: ApplicationRoutes.controlpanel.my,
    urlOfSettings: ApplicationRoutes.controlpanel.settings,
    urlOfMyBlogs: ApplicationRoutes.controlpanel.myBlogs,
    urlOfMyMentorCourses: ApplicationRoutes.controlpanel.myMentorCourses,
    urlOfMySeminars: ApplicationRoutes.controlpanel.mySeminars,
    urlOfMyLearnCourses: ApplicationRoutes.controlpanel.myLearnCourses,
    urlOfMyCommunities: ApplicationRoutes.controlpanel.myCommunities,
    urlOfMyProjects: ApplicationRoutes.controlpanel.myProjects,
    urlOfProjectOpportunity: ApplicationRoutes.controlpanel.projectOpportunity,
    urlOfBlogOpportunity: ApplicationRoutes.controlpanel.blogOpportunity,
    urlOfCommunityOpportunity: ApplicationRoutes.controlpanel.communityOpportunity,
    urlOfMentorOpportunity: ApplicationRoutes.controlpanel.mentorOpportunity,
    urlOfLearnOpportunity: ApplicationRoutes.controlpanel.learnOpportunity,
    urlOfSeminarOpportunity: ApplicationRoutes.controlpanel.seminarOpportunity,
    urlOfMyNotifications: ApplicationRoutes.controlpanel.notification,
    apiOfdashboard: {
      myProjects: `${env.hosts.projectsHost}/api/companyprojects/getcompanyprojects`,
      getDashboardInfo: `${env.hosts.companyApiHost}/api/companydashboard/get`,
      deActivateAccount: `${env.hosts.companyApiHost}/api/companyprofile/deactivateaccount`,
    },
    project: {
      getProjects: `${env.hosts.projectsHost}/api/companyprojects/get`,
      getProject: `${env.hosts.projectsHost}/api/companyproject/get`,
      addProjectDeliverables: `${env.hosts.projectsHost}/api/companyproject/adddeliverable`,
      bookmarkProject: `${env.hosts.projectsHost}/api/companyproject/savefollowprojectstatus`,
      signNda: `${env.hosts.projectsHost}/api/project/acceptnda`,
      featured: `${env.hosts.projectsHost}/api/project/featured`
    },
    starperformers:{
      getStarPerformers: `${env.hosts.starperformersHost}/api/experts`,
    },
    topprojects:{
      getTopProjects: `${env.hosts.topprojectsHost}/api/OpenProjects/GetProjects`,
    },
    blog: {
      getBlog: `${env.hosts.blogHost}/api/Post`,
      getBlogs: `${env.hosts.blogHost}/api/Search`,
      getFeaturedSections: `${env.hosts.blogHost}/api/Featured/Sections`
    },
    community: {
      getFeaturedCommunities: `${env.hosts.messagingHost}/api/Featured`,
      getPopularCommunities: `${env.hosts.messagingHost}/api/Featured/Popular`,
      getCommunityWithChat: `${env.hosts.messagingHost}/api/Community/WithChat`,
      isCommunityNameUnique: `${env.hosts.messagingHost}/api/Communites/IsNameUnique`,
    },
    mentor: {
      getMentorCourse: `${env.hosts.mentorHost}/api/Mentor/Course`,
      getFeaturedMentorCourses: `${env.hosts.mentorHost}/api/Featured/Courses`,
    },
    seminar: {
      getSeminar: `${env.hosts.seminarHost}/api/Seminar`,
      getSeminars: `${env.hosts.seminarHost}/api/Search`,
      getFeaturedSections: `${env.hosts.seminarHost}/api/Featured/Sections`
    },
    learn: {
      getLearnCourse: `${env.hosts.knowledgeHost}/api/Course`
    },
    internship: {
      getInternship: `${env.hosts.employmentHost}/api/engineering/internship`
    },
    job: {
      getJob: `${env.hosts.employmentHost}/api/engineering/jobs`
    }
  },
  expert: {
    profileType: 'expert',
    apiOfprofileImage: {
      get: `${env.hosts.expertApiHost}/api/expertimages/getprofileimage`,
      post: `${env.hosts.expertApiHost}/api/expertimages/UploadProfilePicture`,
    },
    urlOfProfile: ApplicationRoutes.dashboard.profile,
    urlOfDashboard: ApplicationRoutes.dashboard.my,
    urlOfSettings: ApplicationRoutes.dashboard.settings,
    urlOfMyBlogs: ApplicationRoutes.dashboard.myBlogs,
    urlOfMyMentorCourses: ApplicationRoutes.dashboard.myMentorCourses,
    urlOfMySeminars: ApplicationRoutes.dashboard.mySeminars,
    urlOfMyLearnCourses: ApplicationRoutes.dashboard.myLearnCourses,
    urlOfMyCommunities: ApplicationRoutes.dashboard.myCommunities,
    urlOfMyProjects: ApplicationRoutes.dashboard.myProjects,
    urlOfProjectOpportunity: ApplicationRoutes.dashboard.projectOpportunity,
    urlOfBlogOpportunity: ApplicationRoutes.dashboard.blogOpportunity,
    urlOfCommunityOpportunity: ApplicationRoutes.dashboard.communityOpportunity,
    urlOfMentorOpportunity: ApplicationRoutes.dashboard.mentorOpportunity,
    urlOfLearnOpportunity: ApplicationRoutes.dashboard.learnOpportunity,
    urlOfSeminarOpportunity: ApplicationRoutes.dashboard.seminarOpportunity,
    urlOfMyNotifications: ApplicationRoutes.dashboard.notification,
    apiOfdashboard: {
      myProjects: `${env.hosts.projectsHost}/api/ExpertProjects/GetExpertProjects`,
      getDashboardInfo: `${env.hosts.expertApiHost}/api/profile/dashboard`,
      deActivateAccount: `${env.hosts.expertApiHost}/api/profile/DeactivateAccount`,
    },
    project: {
      getProjects: `${env.hosts.projectsHost}/api/expertprojects/get`,
      getProject: `${env.hosts.projectsHost}/api/project`,
      addProjectDeliverables: `${env.hosts.projectsHost}/api/expertproject/adddeliverable`,
      bookmarkProject: `${env.hosts.projectsHost}/api/expertproject/savefollowprojectstatus`,
      signNda: `${env.hosts.projectsHost}/api/project/acceptnda`,
      featured: `${env.hosts.projectsHost}/api/project/featured`
    },
    blog: {
      getBlog: `${env.hosts.blogHost}/api/Post`,
      getBlogs: `${env.hosts.blogHost}/api/Search`,
      getFeaturedSections: `${env.hosts.blogHost}/api/Featured/Sections`
    },
    community: {
      getFeaturedCommunities: `${env.hosts.messagingHost}/api/Featured`,
      getPopularCommunities: `${env.hosts.messagingHost}/api/Featured/Popular`,
      getCommunityWithChat: `${env.hosts.messagingHost}/api/Community/WithChat`,
      isCommunityNameUnique: `${env.hosts.messagingHost}/api/Communites/IsNameUnique`,
    },
    mentor: {
      getMentorCourse: `${env.hosts.mentorHost}/api/Mentor/Course`,
      getFeaturedMentorCourses: `${env.hosts.mentorHost}/api/Featured/Courses`,
    },
    seminar: {
      getSeminar: `${env.hosts.seminarHost}/api/Seminar`,
      getSeminars: `${env.hosts.seminarHost}/api/Search`,
      getFeaturedSections: `${env.hosts.seminarHost}/api/Featured/Sections`
    },
    learn: {
      getLearnCourse: `${env.hosts.knowledgeHost}/api/Course`
    },
    internship: {
      getInternship: `${env.hosts.employmentHost}/api/engineering/internship`
    },
    job: {
      getJob: `${env.hosts.employmentHost}/api/engineering/jobs`
    }
  },
  student: {
    profileType: 'student',
    apiOfprofileImage: {
      get: `${env.hosts.expertApiHost}/api/studentimages/getprofileimage`,
      post: `${env.hosts.expertApiHost}/api/studentimages/UploadProfilePicture`,
    },
    urlOfProfile: ApplicationRoutes.studentDashboard.profile,
    urlOfDashboard: ApplicationRoutes.studentDashboard.my,
    urlOfSettings: ApplicationRoutes.studentDashboard.settings,
    urlOfMyBlogs: ApplicationRoutes.studentDashboard.myBlogs,
    urlOfMyMentorCourses: ApplicationRoutes.studentDashboard.myMentorCourses,
    urlOfMySeminars: ApplicationRoutes.studentDashboard.mySeminars,
    urlOfMyLearnCourses: ApplicationRoutes.studentDashboard.myLearnCourses,
    urlOfMyCommunities: ApplicationRoutes.studentDashboard.myCommunities,
    urlOfMyProjects: ApplicationRoutes.studentDashboard.myProjects,
    urlOfProjectOpportunity: ApplicationRoutes.studentDashboard.projectOpportunity,
    urlOfBlogOpportunity: ApplicationRoutes.studentDashboard.blogOpportunity,
    urlOfCommunityOpportunity: ApplicationRoutes.studentDashboard.communityOpportunity,
    urlOfMentorOpportunity: ApplicationRoutes.studentDashboard.mentorOpportunity,
    urlOfLearnOpportunity: ApplicationRoutes.studentDashboard.learnOpportunity,
    urlOfSeminarOpportunity: ApplicationRoutes.studentDashboard.seminarOpportunity,
    urlOfMyNotifications: ApplicationRoutes.studentDashboard.notification,
    apiOfdashboard: {
      getProjects: `${env.hosts.projectsHost}/api/expertprojects/get`,
      myProjects: `${env.hosts.projectsHost}/api/ExpertProjects/GetExpertProjects`,
      getDashboardInfo: `${env.hosts.expertApiHost}/api/studentdashBoard`,
      deActivateAccount: `${env.hosts.expertApiHost}/api/studentprofile/DeactivateAccount`,
    },
    project: {
      getProjects: `${env.hosts.projectsHost}/api/expertprojects/get`,
      getProject: `${env.hosts.projectsHost}/api/ExpertProject/Get`,
      addProjectDeliverables:``,
      bookmarkProject: `${env.hosts.projectsHost}/api/expertproject/savefollowprojectstatus`,
      signNda: `${env.hosts.projectsHost}/api/project/acceptnda`,
      featured: `${env.hosts.projectsHost}/api/project/featured`
    },
    blog: {
      getBlog: `${env.hosts.blogHost}/api/Post`,
      getBlogs: `${env.hosts.blogHost}/api/Search`,
      getFeaturedSections: `${env.hosts.blogHost}/api/Featured/Sections`
    },
    community: {
      getFeaturedCommunities: `${env.hosts.messagingHost}/api/Featured`,
      getPopularCommunities: `${env.hosts.messagingHost}/api/Featured/Popular`,
      getCommunityWithChat: `${env.hosts.messagingHost}/api/Community/WithChat`,
      isCommunityNameUnique: `${env.hosts.messagingHost}/api/Communites/IsNameUnique`,
    },
    mentor: {
      getMentorCourse: `${env.hosts.mentorHost}/api/Mentor/Course`,
      getFeaturedMentorCourses: `${env.hosts.mentorHost}/api/Featured/Courses`,
    },
    seminar: {
      getSeminar: `${env.hosts.seminarHost}/api/Seminar`,
      getSeminars: `${env.hosts.seminarHost}/api/Search`,
      getFeaturedSections: `${env.hosts.seminarHost}/api/Featured/Sections`
    },
    learn: {
      getLearnCourse: `${env.hosts.knowledgeHost}/api/Course`
    },
    internship: {
      getInternship: `${env.hosts.employmentHost}/api/engineering/internship`
    },
    job: {
      getJob: `${env.hosts.employmentHost}/api/engineering/jobs`
    }
  },
  general: {
    profileType: undefined,
    apiOfprofileImage: {
      get: ``,
      post: ``,
    },
    urlOfProfile: '/',
    urlOfDashboard: '/',
    urlOfSettings: '/',
    urlOfMyBlogs: '/',
    urlOfMyMentorCourses: '/',
    urlOfMySeminars: '/',
    urlOfMyLearnCourses: '/',
    urlOfMyCommunities: '/',
    urlOfMyProjects: '/',
    urlOfProjectOpportunity: '/',
    urlOfBlogOpportunity: '/',
    urlOfCommunityOpportunity: '/',
    urlOfMentorOpportunity: '/',
    urlOfLearnOpportunity: '/',
    urlOfSeminarOpportunity: '/',
    urlOfMyNotifications: '/',
    apiOfdashboard: {
      myProjects: ``,
      getDashboardInfo: ``,
      deActivateAccount: '',
    },
    project: {
      getProjects: `${env.hosts.projectsHost}/api/openprojects/getprojects`,
      getProject: `${env.hosts.projectsHost}/api/project`,
      addProjectDeliverables:`${env.hosts.projectsHost}/api/project/deliverable`,
      bookmarkProject: `${env.hosts.projectsHost}/api/expertproject/savefollowprojectstatus`,
      signNda: `${env.hosts.projectsHost}/api/project/acceptnda`,
      featured: `${env.hosts.projectsHost}/api/project/featured`
    },
    blog: {
      getBlog: `${env.hosts.blogHost}/api/Post`,
      getBlogs: `${env.hosts.blogHost}/api/Search`,
      getFeaturedSections: `${env.hosts.blogHost}/api/Featured/Sections`
    },
    community: {
      getFeaturedCommunities: `${env.hosts.messagingHost}/api/Featured`,
      getPopularCommunities: `${env.hosts.messagingHost}/api/Featured/Popular`,
      getCommunityWithChat: `${env.hosts.messagingHost}/api/Community/WithChat`,
      isCommunityNameUnique: `${env.hosts.messagingHost}/api/Communites/IsNameUnique`,
    },
    mentor: {
      getMentorCourse: `${env.hosts.mentorHost}/api/Mentor/Course`,
      getFeaturedMentorCourses: `${env.hosts.mentorHost}/api/Featured/Courses`,
    },
    seminar: {
      getSeminar: `${env.hosts.seminarHost}/api/Seminar`,
      getSeminars: `${env.hosts.seminarHost}/api/Search`,
      getFeaturedSections: `${env.hosts.seminarHost}/api/Featured/Sections`
    },
    learn: {
      getLearnCourse: `${env.hosts.knowledgeHost}/api/Course`
    },
    internship: {
      getInternship: `${env.hosts.employmentHost}/api/engineering/internship`
    },
    job: {
      getJob: `${env.hosts.employmentHost}/api/engineering/jobs`
    }
  },
};
