import { Injectable } from '@angular/core';
import { RoleBasedConfig } from 'src/app/configs/app-contextual-config';
import { AuthQuery } from '../state/auth-state/auth.query';

export enum ProfileType {
  Company = 'Company',
  Expert = 'Expert',
  Student = 'Student',
}

@Injectable({
  providedIn: 'root',
})
export class ContextualConfigService {
  constructor(private authQuery: AuthQuery) {}

  get roleBasedConfig() {
    const authUser = this.authQuery.getValue().__everyeng__eng_auth;

    if (!authUser.token) return RoleBasedConfig.general;
    // case for server rendered.
    if (authUser?.token == '' && authUser.currentProfileType == '')
      return undefined;

    switch (authUser.currentProfileType) {
      case ProfileType.Company:
        return RoleBasedConfig.company;
      case ProfileType.Expert:
        return RoleBasedConfig.expert;
      case ProfileType.Student:
        return RoleBasedConfig.student;
      default:
        return RoleBasedConfig.general;
    }
  }

  get isCompany() {
    return (
      this.roleBasedConfig && this.roleBasedConfig.profileType === 'company'
    );
  }
  get isExpert() {
    return (
      this.roleBasedConfig && this.roleBasedConfig.profileType === 'expert'
    );
  }
  get isStudent() {
    return (
      this.roleBasedConfig && this.roleBasedConfig.profileType === 'student'
    );
  }
}
