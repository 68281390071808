import { Injectable } from '@angular/core';
import {
  NotificationService,
  NotificationSettings,
} from "@progress/kendo-angular-notification";

@Injectable({
  providedIn: 'root',
})
export class ToastService {

  constructor(private notificationService: NotificationService) {}

  success(message: any) {
    let successOptions: NotificationSettings = {
      content: message,
      cssClass: [
        "wrapper", "custom-noti"
      ],
      type: { style: "success", icon: true },
      animation: { type: "fade", duration: 200 },
      position: { horizontal: "right", vertical: "top" },
      hideAfter: 5000,
      width: 400,
    };
    this.notificationService.show(successOptions);
  }

  error(message: string) {
    const errorOptions: NotificationSettings = {
      content: message,
      cssClass: [
        "wrapper", "custom-noti"
      ],
      type: { style: "error", icon: true },
      animation: { type: "fade", duration: 200 },
      position: { horizontal: "right", vertical: "top" },
      hideAfter: 5000,
      width: 400,
    };
    this.notificationService.show(errorOptions);
  }

  warn(message: string) {
    const warnOptions: NotificationSettings = {
      content: message,
      cssClass: [
        "wrapper", "custom-noti"
      ],
      type: { style: "warning", icon: true },
      animation: { type: "fade", duration: 200 },
      position: { horizontal: "right", vertical: "top" },
      hideAfter: 5000,
      width: 400,
    };
    this.notificationService.show(warnOptions);
  }

  info(message: string) {
    const infoOptions : NotificationSettings = {
      content: message,
      cssClass: [
        "wrapper", "custom-noti"
      ],
      type: { style: "info", icon: true },
      animation: { type: "fade", duration: 200 },
      position: { horizontal: "right", vertical: "top" },
      hideAfter: 5000,
      width: 400,
    };
    this.notificationService.show(infoOptions);
  }
}
